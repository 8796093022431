import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sha256 from 'crypto-js/sha256';
import { Input } from 'antd';
import ContextProvider from '../Context/ContextProvider'
import MainSection from '../MainSection/MainSection';

import 'react-block-ui/style.css';
import 'antd/dist/antd.css';

class App extends Component {

  pw = "10038003ec1ff0f87281d86877c4cbc9893f0964a210c2ff4c699d97e9bbbee4";

  constructor(props) {
    super(props);
    this.state = {
      passwd: "",
    };
    this.handleChangePasswd = this.handleChangePasswd.bind(this);
  }

  handleChangePasswd(event) {
    const value = event.target.value;
    this.setState({
      passwd: sha256(value).toString()
    });
  }

  render() {
    if (this.pw === this.state.passwd) {
      return (
        <div>
          <ContextProvider>
            <MainSection 
              customerCode={this.props.customerCode}
              userToken={this.props.userToken}
            />
          </ContextProvider>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Input.Password placeholder="Enter password" onPressEnter={this.handleChangePasswd}/>
        </div>
      );
    }
  }
}

App.propTypes = {
  customerCode: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired
}

export default App;
