import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShowFilters extends Component {
  static defaultProps = {
    hideProject: false
  }

  render() {
    if (this.props.filters.project.name.length && this.props.filters.region.name.length && this.props.filters.shopType.name.length && this.props.filters.businessType.name.length ) {
      if (this.props.hideProject) {
        return (
        <div className="project-name">
          <div className="subtitle tags are-medium filters">
            <span className="tag is-white first-tag">Filtro:</span>
            <span className="tag is-light is-normal">{this.props.filters.region.name}</span>
            <span className="tag is-light is-normal">{this.props.filters.shopType.name}</span>
            <span className="tag is-light is-normal">{this.props.filters.businessType.name}</span>
          </div>
        </div>
        );
      } else {
        return (
          <React.Fragment>
            <div className="project-name">
              <h4 className="title is-4">
                {this.props.filters.project.name}
              </h4>
              <div className="subtitle tags are-medium filters">
                <span className="tag is-white first-tag">Filtro:</span>
                <span className="tag is-light is-normal">{this.props.filters.region.name}</span>
                <span className="tag is-light is-normal">{this.props.filters.shopType.name}</span>
                <span className="tag is-light is-normal">{this.props.filters.businessType.name}</span>
              </div>
            </div>
          </React.Fragment>
        );
      }
    } else {
      return(
        <p>
          <i>Seleccione los filtros para obtener datos</i>
        </p>
      )
    }
  }
}

ShowFilters.propTypes = {
  hideProject: PropTypes.bool,
  filters: PropTypes.shape({
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default ShowFilters;
