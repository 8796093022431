import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { getURL, setParams } from '../../../utils/request-utils'
import CustomProgress from '../../Shared/CustomProgress/CustomProgress';

class SecondSection extends Component {

  regionNPSAbortController = new window.AbortController();

  emptyNPSByRegion = {
    regions: []
  }
  constructor(props) {
    super(props);
    this.state = {
      loadingNPSByRegion: true,
      npsByRegion: this.emptyNPSByRegion
    };
  }

  componentDidMount() {
    this.loadNPSByRegion();
  }

  componentDidUpdate(prevProps) {
    // Load "NPS by region" data when required filters has been changed
    if (
      this.props.filters.customerCode !== prevProps.filters.customerCode ||
      this.props.filters.userToken !== prevProps.filters.userToken ||
      this.props.filters.project.code !== prevProps.filters.project.code
    ) {
      this.loadNPSByRegion();
    }
  }

  loadNPSByRegion() {
    // Load "NPS by region" data if required filters has been set
    if (
      this.props.filters.customerCode.length &&
      this.props.filters.userToken.length &&
      this.props.filters.project.code.length
    ) {
      this.setState({
        loadingNPSByRegion: true
      });
      this.regionNPSAbortController.abort();
      this.regionNPSAbortController = new window.AbortController();
      
      // MOCK const url = "http://localhost:3001/npsByRegion";
      const url = setParams(getURL('npsByRegion'),
        [
          { id: 'customerCode', value: this.props.filters.customerCode },
          { id: 'userToken', value: this.props.filters.userToken },
          { id: 'projectID', value: this.props.filters.project.code},
        ]
      );

      fetch(url, { signal: this.regionNPSAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            loadingNPSByRegion: false,
            npsByRegion: json
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          loadingNPSByRegion: false,
          npsByRegion: this.emptyNPSByRegion
        });
      });
    }
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column has-text-centered">
            <h4 className="title is-4">NPS POR REGIÓN</h4>
          </div>
        </div>
        <div className="columns is-mobile is-multiline is-centered">
          {this.state.npsByRegion.regions.map((element, index) => {
            return (
              <div className="column is-narrow has-text-centered" key={element.code | index}>
                <BlockUi tag="div" blocking={this.state.loadingNPSByRegion}>
                  <CustomProgress type="dashboard" percent={element.value} />
                  <span>{element.name}</span>
                </BlockUi>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

SecondSection.propTypes = {
  filters: PropTypes.shape({
    customerCode: PropTypes.string.isRequired,
    userToken: PropTypes.string.isRequired,
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default SecondSection;
