import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getURL, setParams } from '../../../utils/request-utils'
import { Context } from '../../Context/ContextProvider';
import ShowFilters from '../../Shared/ShowFilters/ShowFilters';
import Compare from '../../Shared/Compare/Compare';
import Select from '../../Shared/Select/Select';

class FirstSection extends Component {
  static contextType = Context;

  summaryNPSAbortController = new window.AbortController();
  npsChartDataAbortController = new window.AbortController();

  emptySummaryNPS = {
    nps: 0,
    npsPrev1: 0,
    variation1: 0,
    nps3: 0,
    npsPrev3: 0,
    variation3: 0,
    nps12: 0,
    npsPrev12: 0,
    variation12: 0,
    targetValue: 0,
    progressValue: undefined,
    progressDate: ''
  }

  emptyNPSChartData = {
    values: [],
    labelCodes: []
  }

  constructor(props) {
    super(props);
    this.state = {
      loadingSummaryNPS: true,
      loadingNPSChartData: true,
      periodSelected: this.props.periodOptions[0].code,
      summaryNPS: this.emptySummaryNPS,
      npsChartData: this.emptyNPSChartData
      /* Variables: Feature hidden
      variables: {
        columns: [],
        variables: []
      }
      */
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleChangeSelect(event) {
    this.setState({
      periodSelected: event.target.value
    })
  }

  componentDidMount() {
    this.loadSummaryNPS();
    this.loadChartData();
  }

  componentDidUpdate(prevProps, prevState) {
    // Load "summary NPS" data when required filters has been changed
    if (
      this.props.filters.userToken !== prevProps.filters.userToken ||
      this.props.filters.project.code !== prevProps.filters.project.code ||
      this.props.filters.region.code  !== prevProps.filters.region.code ||
      this.props.filters.shopType.code !== prevProps.filters.shopType.code ||
      this.props.filters.businessType.code !== prevProps.filters.businessType.code
    ) {
      this.loadSummaryNPS();
    }

    // Load "line chart" data when required filters has been changed
    if (
      this.props.filters.userToken !== prevProps.filters.userToken ||
      this.props.filters.project.code !== prevProps.filters.project.code ||
      this.props.filters.region.code  !== prevProps.filters.region.code ||
      this.props.filters.shopType.code !== prevProps.filters.shopType.code ||
      this.props.filters.businessType.code !== prevProps.filters.businessType.code ||
      this.state.periodSelected !== prevState.periodSelected
    ) {
      this.loadChartData();
    }
  }

  loadSummaryNPS() {
    // Load "summary NPS" data if required filters has been set
    if ( // Check necesary filters selected
      this.props.filters.userToken.length &&
      this.props.filters.project.code.length &&
      this.props.filters.region.code.length &&
      this.props.filters.shopType.code.length &&
      this.props.filters.businessType.code.length
    ) {
      
      this.setState({
        loadingSummaryNPS: true
      });
      this.summaryNPSAbortController.abort();
      this.summaryNPSAbortController = new window.AbortController();

      // MOCK const url = "http://localhost:3001/summaryNPS"
      const url = setParams(getURL('summaryNPS'),
        [
          { id: 'userToken', value: this.props.filters.userToken },
          { id: 'projectID', value: this.props.filters.project.code},
          { id: 'regionCode', value: this.props.filters.region.code},
          { id: 'shopTypeCode', value: this.props.filters.shopType.code},
          { id: 'businessTypeCode', value: this.props.filters.businessType.code},
        ]
      );

      fetch(url, { signal: this.summaryNPSAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            loadingSummaryNPS: false,
            summaryNPS: json
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          loadingSummaryNPS: false,
          summaryNPS: this.emptySummaryNPS
        });
      });
    }
  }

  loadChartData() {
    // Load "line chart" data if required filters has been set
    if (
      this.props.filters.userToken.length &&
      this.props.filters.project.code.length &&
      this.props.filters.region.code.length &&
      this.props.filters.shopType.code.length &&
      this.props.filters.businessType.code.length &&
      this.state.periodSelected.length
    ) {
      this.setState({
        loadingNPSChartData: true
      });
      this.npsChartDataAbortController.abort();
      this.npsChartDataAbortController = new window.AbortController();

      // MOCK const url = "http://localhost:3001/npsChartData-"+this.state.periodSelected
      const perdiodFilters = this.state.periodSelected.split("_");
      const url = setParams(getURL('npsChartData'),
        [
          { id: 'userToken', value: this.props.filters.userToken },
          { id: 'projectID', value: this.props.filters.project.code},
          { id: 'regionCode', value: this.props.filters.region.code},
          { id: 'shopTypeCode', value: this.props.filters.shopType.code},
          { id: 'businessTypeCode', value: this.props.filters.businessType.code},
          { id: 'groupedAmount', value: perdiodFilters[0]},
          { id: 'quantity', value: perdiodFilters[1]}
        ]
      );

      fetch(url, { signal: this.npsChartDataAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            loadingNPSChartData: false,
            npsChartData: json
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          loadingNPSChartData: false,
          npsChartData: this.emptyNPSChartData
        });
      });
    }
  }

  render() {
    /* Variables: Feature hidden
    const dataSource = [];
    this.state.variables.variables.forEach((variable,index) => {
      const obj = {
        key: index,
        name: variable.name
      };
      variable.values.forEach((value) => {
        obj[value.colName] = value.value;
      });
      dataSource.push(obj);
    });

    const tableColumns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name'
      }
    ];
    this.state.variables.columns.forEach((column) => {
      tableColumns.push(
        {
          title: column.title,
          dataIndex: column.colName,
          key: column.colName,
          render: value => (
            <CustomProgress percent={value} />
          )
        }
      );
    });
    */    

    const targetData = [];
    const labels = [];
    this.state.npsChartData.labelCodes.forEach((e) => {
      // Set target value
      targetData.push(this.state.summaryNPS.targetValue);

      // Set xAxis label
      const splitCodes = e.split("_");
      let label = this.context.getLabel(splitCodes[0]);
      splitCodes.forEach((code, i) => {
          if (i > 0) {
            label = label + " " + this.context.getLabel(code);
          }
      });
      labels.push(label);
    });

    const options = {
      chart: {
          type: 'areaspline'
      },
      title: {
          text: ''
      },
      /*legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 150,
          y: 100,
          floating: true,
          borderWidth: 1,
          backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },*/
      xAxis: {
          categories: labels
      },
      yAxis: {
          title: {
              text: 'NPS'
          },
          startOnTick: false,
          minPadding: 0.2,
          endOnTick: false,
          maxPadding: 0.1,
          max: 100
      },
      tooltip: {
          shared: true
          //valueSuffix: ' units'
      },
      credits: {
          enabled: false
      },
      plotOptions: {
          areaspline: {
              threshold: null,
              fillOpacity: 0.2
          }
      },
      series: [{
          name: this.context.getLabel('WAVE'),
          data: this.state.npsChartData.values
      }, {
          name: this.context.getLabel('TARGET'),
          data: targetData,
          marker: {
            enabled: false
         }
      }]
    }

    
    return (
      <div className="tile is-ancestor">
        <div className="tile is-parent is-4">
          <BlockUi tag="div" className="tile is-child general-summary-info" blocking={this.state.loadingSummaryNPS}>
            <div className="tile is-parent is-12">
              <ShowFilters filters={this.props.filters} />
            </div>
            <div className="columns is-12 is-desktop">
              <div className="is-parent column">
                <div className="nps-coll tile is-child">
                  <div className="nps-state is-dark">
                    <label>NPS:</label>
                    <div className="nps-number">{this.state.summaryNPS.nps ? this.state.summaryNPS.nps.toFixed(1) : "-"}</div>
                  </div>
                   <div className="summary-history box">
                  <div className="summary-header">
                    <Compare title={this.context.getLabel('PREVIOUS_WAVE') +": "+ this.state.summaryNPS.npsPrev1} mainNumber={this.state.summaryNPS.variation1} />
                  </div>
                  <div className="summary-content">
                    <div className="summary-item">
                      <h6 className="subtitle is-6">Acumulado 3: {this.state.summaryNPS.nps3 || "-"}</h6>
                      <Compare mainNumber={this.state.summaryNPS.variation3}/>
                    </div>
                     <div className="summary-item">
                      <h6 className="subtitle is-6"> Acumulado 12: {this.state.summaryNPS.nps12 || "-"}</h6>
                      <Compare mainNumber={this.state.summaryNPS.variation12}/>
                    </div>
                  </div>
                  <div className="summary-footer clearfix">
                    <label className="subtitle is-5 target">{this.context.getLabel('TARGET')}</label>
                    <label className="subtitle is-5 target-value">{this.state.summaryNPS.targetValue}</label>
                  </div>
                </div>
                <div className="progress-date"><span className="tag is-dark is-large">Avance: {this.state.summaryNPS.progressValue} ({this.state.summaryNPS.progressDate})</span></div>
                </div>
              </div>
            </div>
          </BlockUi>
        </div>
        <div className="tile is-parent is-8">
          <div className="tile is-child is-12">
            <div className="column is-12 field">
              <label className="label">Periodo:</label>
              <div className="control">
                <Select name="periodSelect" placeholder="" value={this.state.periodSelected} options={this.props.periodOptions} onChange={this.handleChangeSelect} />
              </div>
            </div>
            <BlockUi tag="div" blocking={this.state.loadingNPSChartData}>
              <div className="graphic column is-narrow box">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                  />
              </div>
            </BlockUi>
            {/* Variables: Feature hidden
            <Row>
              <Table dataSource={dataSource} columns={tableColumns} pagination={false} showHeader={true}/>
            </Row>
            */}
          </div>
        </div>
      </div>
    );
  }
}

FirstSection.propTypes = {
  periodOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  filters: PropTypes.shape({
    customerCode: PropTypes.string.isRequired,
    userToken: PropTypes.string.isRequired,
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default FirstSection;
