import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHowler from 'react-howler'
import { Button } from 'antd';
import { getURL, setParams } from '../../../utils/request-utils'

class AudioPlayer extends Component {
  static defaultProps = {
    documentID: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      available: this.props.documentID > 0,
      loading: false,
      loaded: false,
      src: "",
      playing: false,
      icon: "play-circle",
      handleClick: this.handlePlay
    };
  }

  handlePause = () => {
    this.setState({
      playing: false,
      icon: "play-circle",
      handleClick: this.handlePlay
    })
  }

  handleEnd = () => {
    this.setState({
      playing: false,
      icon: "play-circle",
      handleClick: this.handlePlay
    })
  }

  handleLoadError = () => {
    this.setState({
      available: false
    })
  }

  handlePlay = () => {
    if (this.state.loading) {
      return
    }
    if (
      this.props.documentID > 0 &&
      this.state.src === ""
    ) {
      // Load "audio URL"
      this.setState({
        icon: "loading",
        loading: true
      });
      const url = setParams(getURL('audioURL'),
        [
          { id: 'documentID', value: this.props.documentID },
        ]
      );
      fetch(url)
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
            } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            src: json.src,
            loading: false,
            loaded: true,
            playing: true,
            icon: "pause-circle",
            handleClick: this.handlePause
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          src: "",
          available: false,
          icon: "play-circle",
        });
      });
    } else {
      this.setState({
        playing: true,
        icon: "pause-circle",
        handleClick: this.handlePause
      })
    }
  }

  render() {
    if (this.state.available) {
      if (this.state.loaded) {
      return (
        <div>
          <ReactHowler
            src={[this.state.src]}
            playing={this.state.playing}
            onEnd={this.handleEnd}
            onLoadError={this.handleLoadError}
          />
          <Button type="primary" shape="circle" icon={this.state.icon} size="small" onClick={this.state.handleClick}/>
        </div>
      );
      } else {
      return (
        <div>
          <Button type="primary" shape="circle" icon={this.state.icon} size="small" onClick={this.state.handleClick}/>
        </div>
      );
      }
    } else {
      return (
        <div>
          <Button type="primary" shape="circle" icon="play-circle" size="small" disabled/>
        </div>
      );
    }
  }
}

AudioPlayer.propTypes = {
  documentID: PropTypes.number.isRequired
}

export default AudioPlayer;
