import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../Context/ContextProvider';
import Select from '../../Shared/Select/Select';

class MainFilter extends Component {

  static contextType = Context;

  render() {
    return (
      <div className="navbar-start">
        <div className="navbar-item">
          <Select name="project" placeholder={this.context.getLabel('PROJECT')} value={this.props.filters.project.code} options={this.props.projectOptions} onChange={this.props.handleChangeSelect} />
        </div>
        <div className="navbar-item">
          <Select name="region" placeholder={this.context.getLabel('REGION')} value={this.props.filters.region.code} options={this.props.regionOptions} onChange={this.props.handleChangeSelect} />
        </div>
        <div className="navbar-item">
          <Select name="shopType" placeholder={this.context.getLabel('TYPE_SHOP')} value={this.props.filters.shopType.code} options={this.props.shopTypeOptions} onChange={this.props.handleChangeSelect} />
        </div>
        <div className="navbar-item">
          <Select name="businessType" placeholder={this.context.getLabel('TYPE_BUSINESS')} value={this.props.filters.businessType.code} options={this.props.businessTypeOptions} onChange={this.props.handleChangeSelect} />
        </div>
      </div>
    );
  }
}

MainFilter.propTypes = {
  projectOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  regionOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  shopTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  businessTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default MainFilter;
