import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Empty, Spin } from 'antd';
import { getURL, setParams } from '../../utils/request-utils'
import { Context } from '../Context/ContextProvider';
import MainFilter from './MainFilter/MainFilter';
import FirstSection from './FirstSection/FirstSection';
import SecondSection from './SecondSection/SecondSection';
import ThirdSection from './ThirdSection/ThirdSection';
// Hidden Fourth Section import FourthSection from './FourthSection/FourthSection';
import FifthSection from './FifthSection/FifthSection';

class MainSection extends Component {
  static contextType = Context;

  dashboardConfigAbortController = new window.AbortController();
  projectSettingsAbortController = new window.AbortController();

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      isLoaded: false,
      filterOptions: {
        projects: [],
        regions: [],
        shopTypes: [],
        businessTypes: []
      },
      filters: {
        customerCode: this.props.customerCode, // set as state if any subcomponent needs this filter
        userToken: this.props.userToken, // set as state if any subcomponent needs this filter
        project: { 
          code: '',
          name: ''
        },
        region: { 
          code: '',
          name: ''
        },
        shopType: {
          code: '',
          name: ''
        },
        businessType: {
          code: '',
          name: ''
        }
      },
      menuMobileIsActive: true,
      menuMobileClass: "is-active"
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  componentDidMount() {
    this.loadDashboardConfig();
    this.loadProjectSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    // Load "dashboard config" data when customerCode has been changed
    if (
      this.props.customerCode !== prevProps.customerCode
    ) {
      this.setState(
        prevState => ({
          filters: {
            ...prevState.filters,
            customerCode: this.props.customerCode
          }
        })
      );

      this.loadDashboardConfig();
    }

    // Load "project settings" data when project filter has been changed
    if (
      this.state.filters.project.code !== prevState.filters.project.code
    ) {
      this.loadProjectSettings();
    }
  }

  loadDashboardConfig() {
    // Load "dashboard config" data if customerCode has been set
    if (this.props.customerCode) {
      this.dashboardConfigAbortController.abort();
      this.dashboardConfigAbortController = new window.AbortController();

      // MOCK const url = "http://localhost:3001/dashboardConfig";
      const url = setParams(getURL('dashboardConfig'),
        [{ id: 'customerCode', value: this.props.customerCode }]);

      fetch(url, { signal: this.dashboardConfigAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          // Expected fields:
          if ( json.filters.projects && json.filters.regions && json.filters.shopTypes && json.filters.businessTypes ) {
            this.setState({
              isLoaded: true,
              filterOptions: json.filters,
              filters: { // Auto select 1st option on main filters
                customerCode: this.props.customerCode,
                userToken: this.props.userToken,
                project: json.filters.projects[0],
                region: json.filters.regions[0],
                shopType: json.filters.shopTypes[0],
                businessType: json.filters.businessTypes[0]
              },
            });
          } else {
            this.setState({
              isLoaded: true,
              errorMessage: 'Failed to get data for customer code: ' + this.props.customerCode
            });
          }
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          isLoaded: true,
          errorMessage: 'Failed server communication'
        });
      });
    }
  }

  loadProjectSettings() {
    // Load "project settings" data if project filter has been set
    if (this.state.filters.project.code.length) {
      this.projectSettingsAbortController.abort();
      this.projectSettingsAbortController = new window.AbortController();

      // MOCK const url = "http://localhost:3001/projectSettings";
      const url = setParams(getURL('projectSettings'),
        [{ id: 'projectID', value: this.state.filters.project.code }]);

      fetch(url, { signal: this.projectSettingsAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          // Expected fields:
          if ( json.indicatorScales && json.labels ) {
            // set labels and indicatorScales in context.
            this.context.setLabels(json.labels);
            this.context.setIndicatorScales(json.indicatorScales);
          } else {
            this.setState({
              isLoaded: true,
              errorMessage: 'Failed to get settings for project: ' + this.state.filters.project.name
            });
          }
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          isLoaded: true,
          errorMessage: 'Failed server communication'
        });
      });
    }
  }

  handleChangeSelect(event) {
    const name = event.target.name;
    const value =  event.target.value;
    const text =  event.target.options[event.target.selectedIndex].text;

    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [name]: {code: value, name: text}
        }
      })
    );
  }

  handleClickMenuMobile = () => {
    if (this.state.menuMobileIsActive) {
      this.setState({
        menuMobileIsActive: false,
        menuMobileClass: ""
      });
    } else {
      this.setState({
        menuMobileIsActive: true,
        menuMobileClass: "is-active"
      });
    }
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-narrow">
              <Empty description={this.state.errorMessage} />
            </div>
          </div>
        </div>
      );
    } else if (!this.state.isLoaded) {
      return (
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-narrow">
              <Spin tip="Loading..." />
            </div>
          </div>
        </div>
      );
    } else {
      const periodOptions = [
        // code format: <groupedAmount>_<quantity>
        {code: "1_12", name: this.context.getLabel('WAVE_SINGULAR')},
        {code: "3_8", name: "Acumulado 3 " + this.context.getLabel('WAVE')},
        {code: "12_4", name: "Acumulado 12 " + this.context.getLabel('WAVE')}
      ];

      return (
          <div>
            <div className="header-app">
              <div className="container">
                <nav className="navbar is-light">
                  <div className="navbar-brand">
                    {/*burger btn*/}
                    <a role="button" className={`navbar-burger burger ${this.state.menuMobileClass}`} aria-label="menu" aria-expanded="false" data-target="main-menu" onClick={this.handleClickMenuMobile}>
                      <span aria-hidden="true"></span>
                      <span aria-hidden="true"></span>
                      <span aria-hidden="true"></span>
                    </a>
                  </div>
                  {/*menu*/}
                  <div id="main-menu" className={`navbar-menu ${this.state.menuMobileClass}`}>
                    <MainFilter
                      projectOptions={this.state.filterOptions.projects}
                      regionOptions={this.state.filterOptions.regions}
                      shopTypeOptions={this.state.filterOptions.shopTypes}
                      businessTypeOptions={this.state.filterOptions.businessTypes}
                      handleChangeSelect={this.handleChangeSelect}
                      filters={this.state.filters}
                    />
                  </div>
                </nav>
              </div>
            </div>
            <section className="section">
              <div className="container">
                <div className="box">
                  <FirstSection
                    periodOptions={periodOptions}
                    filters={this.state.filters}
                  />
                </div>

                <div className="box">
                  <SecondSection
                    filters={this.state.filters}
                  />
                </div>
                <div className="box">
                  <ThirdSection
                    filters={this.state.filters}
                  />
                </div>
                
                  {/* Hidden Fourth Section
                  <FourthSection
                    filters={this.state.filters}
                  />
                  */}
                <div className="box">
                  <FifthSection
                    filters={this.state.filters}
                  />
                </div>
              </div>
            </section>
          </div>
      );
    }
  }
}

MainSection.propTypes = {
  customerCode: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired
}

export default MainSection;
