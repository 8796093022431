/* eslint-disable react/prop-types */

import React from 'react'

export const Context = React.createContext()

class ContextProvider extends React.Component {
  state = {
    labels: [
      {
        "code": "TARGET",
        "name": "Objetivo"
      },
      {
        "code": "PROJECT",
        "name": "Proyecto"
      },
      {
        "code": "REGION",
        "name": "Región"
      },
      {
        "code": "TYPE_SHOP",
        "name": "Tipo de local"
      },
      {
        "code": "TYPE_BUSINESS",
        "name": "Tipo de negocio"
      },
      {
        "code": "WAVE",
        "name": "Proyectos"
      },
      {
        "code": "WAVE_SINGULAR",
        "name": "Proyecto"
      }
    ],
    indicatorScales: {
      default: {
        "code": "ZERO_TO_HUNDRED",
        "limits": [{"value": 100, "color": "#81cd25"}]
      },
      attribute: {
        "code": "ZERO_TO_HUNDRED",
        "limits": [{"value": 100, "color": "#81cd25"}]
      }
    },
    setLabels: labels => this.setLabels(labels),
    setIndicatorScales: indicatorScales => this.setIndicatorScales(indicatorScales),
    getLabel: code => this.getLabel(code),
    getIndicatorCode: (indicator) => this.getIndicatorCode(indicator),
    getIndicatorColor: (indicator, value) => this.getIndicatorColor(indicator, value)
  }

  setLabels = labels => {
    this.setState({
      labels: labels
    })
  }

  setIndicatorScales = indicatorScales => {
    this.setState({
      indicatorScales: indicatorScales
    })
  }

  getLabel = code => {
    try {
        return this.state.labels.find(element => element.code === code)['name'];
    }
    catch(error) {
        return '';
    }
  }

  getIndicatorCode = (indicator) => {
    try {
        return this.state.indicatorScales[indicator]['code']
    }
    catch(error) {
        return '';
    }
  }

  getIndicatorColor = (indicator, value) => {
    if (typeof value !== 'number') { return '' }
    try {
        return this.state.indicatorScales[indicator]['limits']
        .find(element => value <= element.value)['color'];
    }
    catch(error) {
        return '';
    }
  }

  render () {
    return (
       <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default ContextProvider