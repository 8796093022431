import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { Context } from '../../Context/ContextProvider';

class CustomProgress extends Component {
  static contextType = Context;

  static defaultProps = {
    percent: undefined,
    type: 'line',
    indicator: 'default',
    color: undefined
  }

  render() {
    const ScaleType = {
      ZERO_TO_TEN: 'ZERO_TO_TEN',
      ZERO_TO_HUNDRED: 'ZERO_TO_HUNDRED',
      MINUS_HUNDRED_TO_HUNDRED: 'MINUS_HUNDRED_TO_HUNDRED',
      INVERTED_ZERO_TO_HUNDRED: 'INVERTED_ZERO_TO_HUNDRED'
    };

    const color = () => {
      if (this.props.color) {
        return this.props.color;
      } else {
      return this.context.getIndicatorColor(this.props.indicator, this.props.percent);
      }
    }
    
    const code = this.context.getIndicatorCode(this.props.indicator);

    switch (code) {
      case ScaleType.ZERO_TO_TEN:
        return (
          <div>
            <Progress strokeColor={color()} type={this.props.type} size="small" percent={typeof this.props.percent === 'number' ? this.props.percent*10 : undefined} format={percent => (typeof this.props.percent === 'number') ? `${percent/10}` : ''} />
          </div>
        );
      case ScaleType.MINUS_HUNDRED_TO_HUNDRED:
        if (this.props.percent >= 0 ) {
          return (
            <div>
              <Progress strokeColor={color()} type={this.props.type} size="small" percent={typeof this.props.percent === 'number' ? this.props.percent : undefined} format={percent => (typeof this.props.percent === 'number') ? `${percent}` : ''} />
            </div>
          );
        } else {
          return (
            <div>
              <Progress strokeColor={color()} type={this.props.type} size="small" percent={typeof this.props.percent === 'number' ? this.props.percent*-1 : undefined} status="exception" format={percent => (typeof this.props.percent === 'number') ? `-${percent}` : ''} />
            </div>
          );
        }
      case ScaleType.INVERTED_ZERO_TO_HUNDRED:
        return (
          <div>
            <Progress strokeColor={color()} type={this.props.type} size="small" percent={typeof this.props.percent === 'number' ? this.props.percent : undefined} status="exception" format={percent => typeof this.props.percent === 'number' ? `${percent}` : ''} />
          </div>
        );
      case ScaleType.ZERO_TO_HUNDRED:
        return (
          <div>
            <Progress strokeColor={color()} type={this.props.type} size="small" percent={typeof this.props.percent === 'number' ? this.props.percent : undefined} format={percent => typeof this.props.percent === 'number' ? `${percent}` : ''} />
          </div>
        );
      default:
      return (
        <div>
          <Progress strokeColor={color()} type={this.props.type} size="small" width={40} percent={99.9} format={percent => typeof this.props.percent === 'number' ? `${this.props.percent}` : ''} />
        </div>
      );
    }
  }
}

CustomProgress.propTypes = {
  percent: PropTypes.number,
  type: PropTypes.string,
  indicator: PropTypes.oneOf(['default', 'attribute', 'score']),
  color: PropTypes.string
}

export default CustomProgress;
