import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Table } from 'antd';
import { getURL, setParams } from '../../../utils/request-utils'
import ShowFilters from '../../Shared/ShowFilters/ShowFilters';
import CustomProgress from '../../Shared/CustomProgress/CustomProgress';
import AudioPlayer from '../../Shared/AudioPlayer/AudioPlayer';
import WorkOrderDetails from '../../Shared/WorkOrderDetails/WorkOrderDetails';

class FifthSection extends Component {

  improvementSuggestionsAbortController = new window.AbortController();
  
  emptyImprovementSuggestions = {
    title: "Sugerencias de mejora",
    items: []   
  }

  constructor(props) {
    super(props);
    this.state = {
      loadingImprovementSuggestions: true,
      improvementSuggestions: this.emptyImprovementSuggestions,
    };
  }

  componentDidMount() {
    this.loadImprovementSuggestions();
  }

  componentDidUpdate(prevProps, prevState) {
    // Load "improvement suggestions" data when required filters has been changed
    if (
      this.props.filters.customerCode !== prevProps.filters.customerCode ||
      this.props.filters.userToken !== prevProps.filters.userToken ||
      this.props.filters.project.code !== prevProps.filters.project.code ||
      this.props.filters.region.code  !== prevProps.filters.region.code ||
      this.props.filters.shopType.code !== prevProps.filters.shopType.code ||
      this.props.filters.businessType.code !== prevProps.filters.businessType.code
    ) {
      this.loadImprovementSuggestions();
    }
  }

  loadImprovementSuggestions() {
    // Load "improvement suggestions" data if required filters has been set
    if (
      this.props.filters.customerCode.length &&
      this.props.filters.userToken.length &&
      this.props.filters.project.code.length &&
      this.props.filters.region.code.length &&
      this.props.filters.shopType.code.length &&
      this.props.filters.businessType.code.length
    ) {
      this.setState({
        loadingImprovementSuggestions: true
      });
      this.improvementSuggestionsAbortController.abort();
      this.improvementSuggestionsAbortController = new window.AbortController();

      // MOCK const url = "http://localhost:3001/improvementSuggestions";
      const url = setParams(getURL('improvementSuggestions'),
        [
          { id: 'customerCode', value: this.props.filters.customerCode },
          { id: 'userToken', value: this.props.filters.userToken },
          { id: 'projectID', value: this.props.filters.project.code},
          { id: 'regionCode', value: this.props.filters.region.code},
          { id: 'shopTypeCode', value: this.props.filters.shopType.code},
          { id: 'businessTypeCode', value: this.props.filters.businessType.code},
          { id: 'pageSize', value: 0},
          { id: 'pageNumber', value: 0},
        ]
      );

      fetch(url, { signal: this.improvementSuggestionsAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            loadingImprovementSuggestions: false,
            improvementSuggestions: json
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          loadingImprovementSuggestions: false,
          improvementSuggestions: this.emptyImprovementSuggestions
        });
      });
    }
  }

  render() {
    const dataSource = [];
    this.state.improvementSuggestions.items.forEach((revision,index) => {
      dataSource.push(
        {
          key: index,
          date: revision.date,
          score: revision.score,
          workOrder: revision.workOrder,
          comment: revision.comment
        }
      );
    });
      
    const tableColumns = [
      {
        title: 'CALIF.',
        dataIndex: 'score',
        key: 'score',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.score.value - b.score.value,
        render: score => {
          return {
            children: <CustomProgress type="circle" indicator="score" percent={score.value} color={score.color}/>,
            props: {'data-label': 'CALIF: '}
          }
        }
      },
      {
        title: 'FECHA',
        dataIndex: 'date',
        key: 'date',
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.date < b.date,
        render: date => {
          return {
            children: <span>{new Date(date).toLocaleDateString('en-GB',{year:"2-digit",month:"2-digit", day:"2-digit"})}</span>,
            props: {'data-label': 'FECHA: '}
          }
        }
      },
      {
        title: 'ODT',
        dataIndex: 'workOrder',
        key: 'workOrder',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.workOrder.name - b.workOrder.name,
        render: workOrder => {
          return {
            children: <div className="columns">
            <div className="column is-narrow">
              <AudioPlayer documentID={workOrder.documentID}/>
            </div>
            <div className="column is-narrow">
              <WorkOrderDetails userToken={this.props.filters.userToken} workOrderID={workOrder.name}/>
            </div>
          </div>,
            props: {'data-label': 'ODT: '}
          }
        }
      },
      {
        title: 'MENCIÓN',
        dataIndex: 'comment',
        key: 'comment',
        render: comment => {
          return {
            children: <span>{comment}</span>,
            props: {'data-label': 'MENCIÓN: '}
          }
        }
      }
    ];

    const paginationConfig = {
      pageSize: 25,
      showTotal: (total, range) => `${range[0]}-${range[1]} (${total})`
    };

    return (
      <div>
        <div className="columns">
          <div className="column has-text-centered">
            <h4 className="title is-4">{this.state.improvementSuggestions.title.toUpperCase()}</h4>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <ShowFilters filters={this.props.filters} hideProject={true} />
          </div>
        </div>
        <div className="columns">
          <div className="column has-text-centered">
            <BlockUi tag="div" blocking={this.state.loadingImprovementSuggestions}>
              <Table className="responsive-table" dataSource={dataSource} columns={tableColumns} size="middle" pagination={paginationConfig} scroll={{ x: true }}/>
            </BlockUi>
          </div>
        </div>
      </div>
    );
  }
}

FifthSection.propTypes = {
  filters: PropTypes.shape({
    customerCode: PropTypes.string.isRequired,
    userToken: PropTypes.string.isRequired,
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default FifthSection;
