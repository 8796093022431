import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { getURL, setParams } from '../../../utils/request-utils'

class WorkOrderDetails extends Component {
  static defaultProps = {
    documentID: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      available: this.props.workOrderID > 0,
      loading: false,
      src: "",
      icon: "zoom-in"
    };
  }

  handleClick = () => {
    if (this.state.loading) {
      return
    }
    if (
      this.props.workOrderID > 0 &&
      this.state.src === ""
    ) {
      // Load "work order details URL"
      this.setState({
        icon: "loading",
        loading: true
      });
      const url = setParams(getURL('workOrderDetailsURL'),
        [
          { id: 'userToken', value: this.props.userToken },
          { id: 'workOrderID', value: this.props.workOrderID },
        ]
      );
      fetch(url)
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
            } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            src: json.src,
            loading: false,
            icon: "zoom-in"
          });
          this.openTab();
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          src: "",
          available: false,
          icon: "zoom-in",
        });
      });
    } else {
      this.openTab();
    }
  }

  openTab = () => {
    window.open(this.state.src, '_blank');
  }

  render() {
    if (this.state.available) {
      return (
        <div>
          <Button type="primary" shape="round" icon={this.state.icon} size="small" onClick={this.handleClick}>{this.props.workOrderID}</Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button type="primary" shape="round" icon={this.state.icon} size="small" disabled>{this.props.workOrderID}</Button>
        </div>
      );
    }
  }
}

WorkOrderDetails.propTypes = {
  userToken: PropTypes.string.isRequired,
  workOrderID: PropTypes.string.isRequired
}

export default WorkOrderDetails;
