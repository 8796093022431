import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Table } from 'antd';
import { getURL, setParams } from '../../../utils/request-utils'
import Compare from '../../Shared/Compare/Compare';
import CustomProgress from '../../Shared/CustomProgress/CustomProgress';

class ThirdSection extends Component {

  attributesAbortController = new window.AbortController();

  emptySummaryAttributes = {
    extraColumnHeaders : [],
    attributes : []    
  }
  constructor(props) {
    super(props);
    this.state = {
      loadingSummaryAttributes: true,
      summaryAttributes: this.emptySummaryAttributes
    };
  }

  componentDidMount() {
    this.loadSummaryAttributes();
  }

  componentDidUpdate(prevProps, prevState) {
    // Load "summary attributes" data when required filters has been changed
    if (
      this.props.filters.customerCode !== prevProps.filters.customerCode ||
      this.props.filters.userToken !== prevProps.filters.userToken ||
      this.props.filters.project.code !== prevProps.filters.project.code
    ) {
      this.loadSummaryAttributes();
    }
  }

  loadSummaryAttributes() {
    // Load "summary attributes" data if required filters has been set
    if (
      this.props.filters.customerCode.length &&
      this.props.filters.userToken.length &&
      this.props.filters.project.code.length
    ) {
      this.setState({
        loadingSummaryAttributes: true
      });
      this.attributesAbortController.abort();
      this.attributesAbortController = new window.AbortController();
      
      // MOCK const url = "http://localhost:3001/summaryAttributes";

      const url = setParams(getURL('summaryAttributes'),
        [
          { id: 'customerCode', value: this.props.filters.customerCode },
          { id: 'userToken', value: this.props.filters.userToken },
          { id: 'projectID', value: this.props.filters.project.code},
        ]
      );

      fetch(url, { signal: this.attributesAbortController.signal })
      .then(
        (response) => {
          if (response.ok) {
            return response.json();
           } else {
            throw Error(response.statusText);
          }
        }
      )
      .then(
        (json) => {
          this.setState({
            loadingSummaryAttributes: false,
            summaryAttributes: json
          });
        }
      )
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({
          loadingSummaryAttributes: false,
          summaryAttributes: this.emptySummaryAttributes
        });
      });
    }
  }

  render() {
    const dataSource = [];
    this.state.summaryAttributes.attributes.forEach((attribute,index) => {
      const obj = {
        key: index,
        name: attribute.name,
        variation: attribute.variationPrev,
        indicatorValue: attribute.indicatorValue
      };
      attribute.extraColumnValues.forEach((region) => {
        obj[region.code] = region.value;
      });
      dataSource.push(obj);
    });
      
    const tableColumns = [
      {
        title: 'Atributo',
        dataIndex: 'name',
        key: 'name',
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name < b.name,
        render: name => {
          return {
            children: name,
            props: {'data-label': 'Atributo: '}
          }
        }
      },
      {
        title: 'Variación',
        dataIndex: 'variation',
        key: 'variation',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.variation - b.variation,
        render: variation => {
          return {
            children: <Compare mainNumber={variation}/>,
            props: {'data-label': 'Variación: '}
          }
        }
      },
      {
        title: 'Indicador',
        dataIndex: 'indicatorValue',
        key: 'indicatorValue',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.indicatorValue - b.indicatorValue,
        render: indicatorValue => {
          return {
            children: <CustomProgress indicator="attribute" percent={indicatorValue}/>,
            props: {'data-label': 'Indicador: '}
          }
        }
      }
    ];

    this.state.summaryAttributes.extraColumnHeaders.forEach((region) => {
      tableColumns.push(
        {
          title: region.name,
          dataIndex: region.code,
          key: region.code,
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a[region.code] - b[region.code],
          render: regionValue => {
            return {
              children: <Compare mainNumber={regionValue}/>,
              props: {'data-label': region.name + ': '}
            }
          }
        }
      );
    });

    return (
      <div>
        <div className="columns">
          <div className="column has-text-centered">
            <h4 className="title is-4">ATRIBUTOS</h4>
          </div>
        </div>
        <div className="columns">
          <div className="column has-text-centered">
            <BlockUi tag="div" blocking={this.state.loadingSummaryAttributes}>
              <Table className="responsive-table" dataSource={dataSource} size="middle" columns={tableColumns} pagination={false} scroll={{ x: true }}/>
            </BlockUi>
          </div>
        </div>
      </div>
    );
  }
}

ThirdSection.propTypes = {
  filters: PropTypes.shape({
    customerCode: PropTypes.string.isRequired,
    userToken: PropTypes.string.isRequired,
    project: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    region: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    shopType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    businessType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
}

export default ThirdSection;
