import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Select extends Component {
  static defaultProps = {
    value: '',
    placeholder: ''
  }

  render() {
    const options = this.props.options.map((item) => <option key={item.code.toString()} value={item.code}>{item.name}</option> );
    if (this.props.placeholder.length) {
      return (
        <div className="select">
          <select name= {this.props.name} value={this.props.value} onChange={this.props.onChange}>
            <option key={"default"} disabled value="" >{this.props.placeholder}</option>
            {options}
          </select>
        </div>
      );
    } else {
      return (
        <div className="select">
          <select name= {this.props.name} value={this.props.value} onChange={this.props.onChange}>
            {options}
          </select>
        </div>
      );
    }
    
  }
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Select;
