export const environment = {
    baseUrl: 'https://dashboards-go-cdn.auditorservice.com/api/',
    endpoints: {
        dashboardConfig: "v1/dashboard-config/:customerCode",
        projectSettings: "v1/project-settings/:projectID",
        summaryNPS: "v1/summary-nps/userToken/:userToken/projectID/:projectID/regionCode/:regionCode/shopTypeCode/:shopTypeCode/businessTypeCode/:businessTypeCode",
        npsChartData: "v1/nps-chart-data/userToken/:userToken/projectID/:projectID/regionCode/:regionCode/shopTypeCode/:shopTypeCode/businessTypeCode/:businessTypeCode/groupedAmount/:groupedAmount/quantity/:quantity",
        npsByRegion: "v1/nps-by-region/customerCode/:customerCode/userToken/:userToken/projectID/:projectID",
        summaryAttributes: "v1/summary-attributes/customerCode/:customerCode/userToken/:userToken/projectID/:projectID",
        improvementSuggestions: "v1/improvement-suggestions/customerCode/:customerCode/userToken/:userToken/projectID/:projectID/regionCode/:regionCode/shopTypeCode/:shopTypeCode/businessTypeCode/:businessTypeCode/page-size/:pageSize/page-number/:pageNumber",
        audioURL: "v1/audio-url/documentID/:documentID",
        workOrderDetailsURL: "v1/work-order-details/userToken/:userToken/workOrderID/:workOrderID"
    }
}
