import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';

class Compare extends Component {
  static defaultProps = {
    title: '',
    toCompareNumber: 0
  }

  render() {
    if (typeof this.props.mainNumber !== 'number') { // Empty result
      return (
        <div></div>
      );
    }
    const result = this.props.mainNumber - this.props.toCompareNumber;
    if (result < 0) { // Negative result
      return (
        <Statistic
          title={this.props.title}
          value={Math.abs(result)}
          precision={1}
          valueStyle={{ color: '#242424' }}
          prefix={<div className="arrow arrow-down" />}
          suffix=''
        />
      );
    }

    if (result > 0) { // Positive result
      return (
        <Statistic
          value={Math.abs(result)}
          title={this.props.title}
          precision={1}
          valueStyle={{ color: '#242424' }}
          prefix={<div className="arrow arrow-up" />}
          suffix=''
        />
      );
    }
    // Neutral result
    return (
      <Statistic
        value={Math.abs(result)}
        title={this.props.title}
        precision={1}
        valueStyle={{ color: '#242424' }}
        prefix={<div className="arrow arrow-neutral" />}
        suffix=''
      />
    );
  }
}

Compare.propTypes = {
  mainNumber: PropTypes.number,
  toCompareNumber: PropTypes.number,
  title: PropTypes.string
}

export default Compare;
