// Change link in /enviromnts to change env.
import { environment } from '../environments/environment';

/**
* @param endpoint String field set in environment.endpoints
* @returns String with the complete URL for an endpoint set in environment file.
*/
function getURL (endpoint) {
    return `${environment['baseUrl']}${environment['endpoints'][endpoint]}`;
}

/**
* @param url Sting wich contains an URL with parameters format as :param. For example: /login/:username/:passwd
* @param params Array with objects with the param id and the param value. For example: [{id:'username', value: 'user01'}, {id:'passwd', value: '123'}]
* @returns String with the params of the endpoint replaced. For example: /login/user01/123
*/
function setParams (url, params) {
    params.forEach(param => {
        const regExp = new RegExp(`:${param.id}`);
        if (regExp.test(url)) {
            url = url.replace(`:${param.id}`, String(param.value));
        }
    });
    return url;
}

export { getURL, setParams }
